import "../../styles/pageHome.scss";

// Header function
export default function Partenaire() {
  return (
    <section id="sectionPartenaire" className="sectionDisplay nosPartenaire">
      <h2>NOS PARTENAIRES</h2>
      <div className="divPartenaire">
        <p className="text">A venir</p>
      </div>
    </section>
  );
}
